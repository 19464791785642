import { useLocation } from '@reach/router';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * Custom hook to return GuestName
 * @return {string}
 */
function useFamily() {
  const location = useLocation();
  const type = getQueryValue(location, 'family');

  return type === '1';
}

export default useFamily;
