import React, { useState, useMemo, useCallback } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
  Heading,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import LazyImage from '@/components/Common/LazyImage';
import GuestInformation from './GuestInformation';

import { useGuest } from '@/context/guest';
import useFormFields from '@/hooks/useFormFields';
import useFamily from '@/hooks/useFamily';
import useRsvp from '@/usecase/use-rsvp';
import useAddNewGuest from '@/usecase/use-add-new-guest';

import { BUTTON_PROPS } from '@/constants/colors';
import { ASSETS_FLOWER_LEFT } from '@/constants/assets';
import { API_ATTEND_INFO } from '@/constants/api';

import { RSVP_STATUS } from '@/constants/api';
import * as T from './types';
import * as F from '@/constants/feature-flags';

import { txtForm as txt } from './locales';
import { MemoPhoneNumberList, MemoPersonalizeList } from './Utils';

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const { guest, onUpdateGuest, isRegistered } = useGuest();

  const { onAddNewGuest } = useAddNewGuest();
  const { onUpdateRsvp } = useRsvp();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const isFamily = useFamily();

  const filteredEventInfo = useMemo(() => {
    if (!F.RSVP_ENABLE_ATTEND_INFO || !F.ENABLE_SHIFT_TIME_RECEPTION) return [];

    return API_ATTEND_INFO.filter((item) => item.shift.includes(guest.shift));
  }, [guest.shift]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorType, setErrorType] = useState(T.ERROR_TYPE);
  const { formFields, createChangeHandler } = useFormFields({
    name: `${isRegistered ? guest.name : ''}`,
    address: guest.address === '-' ? '' : guest.address || '',
    email: guest.email === '-' ? '' : guest.email || '',
    phone_number: guest.phone_number,
    guest_quota: guest.guest_quota,
    total_guest: guest.guest_confirm || guest.guest_quota || 1,
    is_attended: guest.status === 'NOT_ATTEND' ? T.TYPE.NO : T.TYPE.YES,
    attend_info: F.RSVP_ENABLE_ATTEND_INFO ? guest.attend_info || filteredEventInfo[0]?.value : '',
  });

  const [selectedCountryCode, setSelectedCountryCode] = useState(guest.country_code || '62');

  const handleCountryCodeChange = useCallback((code) => {
    setSelectedCountryCode(code);
  }, []);

  const callToasterError = (desc, title = 'Oops!') => {
    toast({
      title,
      description: desc,
      status: 'error',
      isClosable: true,
    });
  };

  const callToasterSuccess = (desc, title = txt.success[lang]) => {
    toast({
      title,
      description: desc,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  /**
   * function to submit to BE with check the form value first
   */
  const onSubmitForm = async () => {
    const {
      name,
      phone_number,
      address,
      total_guest,
      is_attended,
      email,
      attend_info,
    } = formFields;
    // reset error state
    setErrorType(T.ERROR_TYPE);
    // define current error state
    let currentErrorType = T.ERROR_TYPE;

    // Make sure user already input the value
    /**
     * ERROR CHECKING
     */
    if (F.RSVP_ENABLE_NAME && !name) {
      currentErrorType = { ...currentErrorType, name: txt.required[lang] };
    }

    if (F.RSVP_ENABLE_PHONE_NUMBER) {
      if (!phone_number) {
        currentErrorType = { ...currentErrorType, phone: txt.required[lang] };
      } else if (phone_number.length > 13 || phone_number.length < 5) {
        currentErrorType = { ...currentErrorType, phone: txt.invalidPhone[lang] };
      }
    }

    if (F.RSVP_ENABLE_ADDRESS && !address) {
      currentErrorType = { ...currentErrorType, address: txt.required[lang] };
    }

    if (F.RSVP_ENABLE_EMAIL) {
      if (!email) {
        currentErrorType = { ...currentErrorType, email: txt.required[lang] };
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        currentErrorType = { ...currentErrorType, email: txt.invalidEmail[lang] };
      }
    }

    if (
      currentErrorType.address ||
      currentErrorType.email ||
      currentErrorType.name ||
      currentErrorType.phone
    ) {
      setErrorType(currentErrorType);
      return;
    }

    setIsLoading(true);
    const guestPayload = {
      name,
      email: email || '-',
      desc: guest.desc || '-',
      shift: guest.shift || '-',
      countryCode: selectedCountryCode,
      phoneNumber: phone_number || '-',
      address: address || '-',
      attendInfo: is_attended === T.TYPE.YES ? attend_info : '',
      system_info: 'WARNING_Terdapat Perubahan dari User_',
    };
    // Usecase: Group or General Invitation
    // We will `insert` instead of `update` if user doens't have code yet. But,
    // we will throw error if QR Invitation
    if (!guest.code) {
      if (F.ENABLE_QR_INVITATION) {
        setIsLoading(false);
        callToasterError(txt.msgError[lang]);
        return;
      }
      await onAddNewGuest({
        ...guestPayload,
        desc: guest.name || '-',
        guest_quota: guest.guest_quota,
        guest_confirm: total_guest && is_attended === T.TYPE.YES ? total_guest : 0,
        status: is_attended === T.TYPE.YES ? RSVP_STATUS.attend : RSVP_STATUS.notAttend,
        onSuccess: (guestCode) => {
          onUpdateGuest(guestCode, name);
          setIsLoading(false);
          callToasterSuccess(txt.msgSuccess[lang]);
        },
        onFailed: () => {
          setIsLoading(false);
          callToasterError(txt.msgError[lang]);
        },
      });
      return;
    }

    await onUpdateRsvp({
      ...guestPayload,
      isAttended: is_attended === T.TYPE.YES,
      totalGuest: total_guest && is_attended === T.TYPE.YES ? total_guest : 0,
      onSuccess: () => {
        setIsLoading(false);
        callToasterSuccess(txt.msgSuccess[lang]);
      },
      onFailed: () => {
        setIsLoading(false);
        callToasterError(txt.msgError[lang]);
      },
    });
  };

  return (
    <Box bgColor="bgPrimary" padding="24px 0 62px 0" {...rest}>
      <Box width="100%" maxW="calc(500px)" position="absolute">
        <LazyImage
          src={ASSETS_FLOWER_LEFT}
          height="200px"
          position="absolute"
          left="30px"
          zIndex={1}
        />
      </Box>
      <Box padding="0 16px">
        <Box marginTop="42px" padding="24px 20px" width="100%" zIndex={2} position="relative">
          <Box padding="8px" bgColor="bgSecondary" borderRadius="16px">
            <WithAnimation>
              <Box padding="24px 16px" width="100%">
                {/* Title & Desc Section */}
                <Box textAlign="center">
                  <Heading fontWeight="normal" color="white" fontSize="42px">
                    {txt.title[lang]}
                  </Heading>
                  <Text
                    color="white"
                    fontSize="md"
                    margin="16px 0 24px 0"
                    padding="0 12px 0 12px"
                    dangerouslySetInnerHTML={{ __html: txt.desc[lang] }}
                  />
                </Box>

                {/* Form Sections - Name */}
                <FormControl margin="8px 0" isInvalid={errorType.name}>
                  <FormLabel {...T.FORM_LABEL_PROPS} margin="8px 0 0 0">
                    {txt.name[lang]}:
                  </FormLabel>
                  <FormLabel color="white" fontSize="sm" fontStyle="italic">
                    {txt.nameHelper[lang]}
                  </FormLabel>
                  <Input
                    {...T.INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={formFields.name}
                    onChange={createChangeHandler('name')}
                    disabled={isRegistered}
                  />
                  <FormErrorMessage {...T.ERROR_PROPS}>{`* ${errorType.name}`}</FormErrorMessage>
                </FormControl>
                {/* Form Sections - Phone Number */}
                <MemoPhoneNumberList
                  value={String(formFields.phone_number)}
                  createChangeHandler={createChangeHandler}
                  lang={lang}
                  selectedCountryCode={String(selectedCountryCode)}
                  errorPhone={errorType.phone}
                  handleCountryCodeChange={handleCountryCodeChange}
                />
                {/* Form Sections - Address */}
                {F.RSVP_ENABLE_ADDRESS && (
                  <FormControl margin="8px 0" isInvalid={errorType.address}>
                    <FormLabel {...T.FORM_LABEL_PROPS}>{txt.address[lang]}:</FormLabel>
                    <Input
                      {...T.INPUT_COMMON_PROPS}
                      height="40px"
                      placeholder="..."
                      value={formFields.address}
                      onChange={createChangeHandler('address')}
                    />
                    <FormErrorMessage {...T.ERROR_PROPS}>
                      {'* ' + errorType.address}
                    </FormErrorMessage>
                  </FormControl>
                )}

                {/* Form Sections - Email */}
                {F.RSVP_ENABLE_EMAIL && (
                  <FormControl margin="8px 0" isInvalid={errorType.email}>
                    <FormLabel {...T.FORM_LABEL_PROPS}>Email:</FormLabel>
                    <Input
                      {...T.INPUT_COMMON_PROPS}
                      placeholder="..."
                      value={formFields.email}
                      onChange={createChangeHandler('email')}
                    />
                    <FormErrorMessage marginTop="4px">{errorType.email}</FormErrorMessage>
                  </FormControl>
                )}

                {/* Form Sections - Attendance */}
                <FormControl margin="8px 0">
                  <FormLabel {...T.FORM_LABEL_PROPS}>{txt.willYoutAttend[lang]}</FormLabel>
                  <Select
                    {...T.SELECT_COMMON_PROPS}
                    value={formFields.is_attended}
                    onChange={createChangeHandler('is_attended')}
                  >
                    <option value={T.TYPE.YES}>{txt.willAttend[lang]}</option>
                    <option value={T.TYPE.NO}>{txt.noAttend[lang]}</option>
                  </Select>
                </FormControl>

                {/* Form Section - Type of Event */}
                {F.RSVP_ENABLE_ATTEND_INFO && formFields.is_attended === T.TYPE.YES && (
                  <FormControl>
                    <FormLabel {...T.FORM_LABEL_PROPS}>Which event will you attend?</FormLabel>
                    <Select
                      {...T.SELECT_COMMON_PROPS}
                      value={formFields.attend_info}
                      onChange={createChangeHandler('attend_info')}
                    >
                      {filteredEventInfo.map((item) => {
                        return (
                          <option key={item.value} value={item.value} style={{ color: 'black' }}>
                            {item.title}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}

                {/* Form Sections - Partner */}
                {formFields.is_attended === T.TYPE.YES && false && (
                  <MemoPersonalizeList
                    lang={lang}
                    totalQuota={guest.guest_quota}
                    value={formFields.total_guest}
                    onChange={createChangeHandler('total_guest')}
                  />
                )}

                <Flex justifyContent="end">
                  {F.ENABLE_GUEST_INFORMATION && (
                    <Button
                      bgColor="bgPrimary"
                      marginTop="24px"
                      size="sm"
                      type="button"
                      fontFamily="body"
                      fontWeight="normal"
                      fontSize="xs"
                      color="mainColorText"
                      onClick={onOpen}
                      borderRadius="8px"
                      marginRight="8px"
                      {...BUTTON_PROPS}
                    >
                      {isFamily ? 'FAMILY INFORMATION' : 'GUEST INFORMATION'}
                    </Button>
                  )}
                  <Button
                    bgColor="bgAlternative"
                    isLoading={isLoading}
                    marginTop="24px"
                    size="sm"
                    fontSize="small"
                    type="button"
                    fontFamily="body"
                    fontWeight="normal"
                    color="white"
                    borderRadius="8px"
                    letterSpacing="1px"
                    textTransform="uppercase"
                    onClick={onSubmitForm}
                    {...BUTTON_PROPS}
                  >
                    {txt.submit[lang]}
                  </Button>
                </Flex>
              </Box>
            </WithAnimation>
          </Box>
        </Box>
        <GuestInformation visible={isOpen} onClose={onClose} lang={lang} />
      </Box>
      {/* Images Bottom */}
      <Box width="100%" maxW="500px" position="absolute">
        <LazyImage
          src={ASSETS_FLOWER_LEFT}
          height="200px"
          position="absolute"
          right="30px"
          transform="rotate(180deg)"
          marginTop="-150px"
          zIndex={1}
        />
      </Box>
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
