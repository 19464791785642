import { useState } from 'react';
import useMutation from '@/hooks/useMutation';
import { ACTION_POST_TYPE, API_TABLE_TYPE } from '@/constants/api';
import noop from '@invitato/helpers/dist/noop';

/**
 * @function useAddNewGuest
 * @description a custom hook to add new guest
 */

function useAddNewGuest() {
  const { onMutation } = useMutation(API_TABLE_TYPE.rsvp, ACTION_POST_TYPE.insertGuest);
  const [guestCode, setGuestCode] = useState('');

  /**
   * @function handleAddNewGuest
   * @description a function handler to add new guest
   */

  const handleAddNewGuest = async ({
    name,
    desc,
    address,
    countryCode,
    phoneNumber,
    email,
    guest_quota,
    guest_confirm,
    shift,
    status,
    attendInfo = '',
    system_info,
    onSuccess = noop,
    onFailed = noop,
  }) => {
    const options =
      `&name=${encodeURIComponent(name)}` +
      `&desc=${encodeURIComponent(desc)}` +
      `&address=${encodeURIComponent(address)}` +
      `&country_code=${countryCode}` +
      `&phone_number=${phoneNumber}` +
      `&email=${email}` +
      `&guest_quota=${guest_quota}` +
      `&guest_confirm=${guest_confirm}` +
      `&shift=${shift}` +
      `&status=${status}` +
      `&attend_info=${encodeURIComponent(attendInfo)}` +
      `&system_info=${encodeURIComponent(system_info)}`;

    const result = await onMutation(options);

    if (result.success) {
      setGuestCode(result.guestCode);
      onSuccess(result.guestCode);
    } else {
      onFailed();
    }
  };

  return { onAddNewGuest: handleAddNewGuest, guestCode: guestCode };
}

export default useAddNewGuest;